import { CustomFieldType, VisitsModel } from '@w3lcome/types';
import { Button, Header, CustomFieldInput } from '_/components';
import ChevronDown from '_/components/ChevronDown';
import ContainerWidthLimit from '_/components/ContainerWidthLimit';
import KeyboardContainer from '_/components/KeyboardContainer';
import { colors } from '_/config/theme';
import { useAuth } from '_/hooks/AuthContext';
import { useCustomization } from '_/hooks/CustomizationContext';
import { useVisit } from '_/hooks/VisitContext';
import { useNextCheckinScreen } from '_/hooks/useNextCheckinScreen';
import { CustomFieldWithError } from '_/interfaces/CustomFieldWithError';
import { AppRoute } from '_/navigation/types';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  View,
  StyleSheet,
  ScrollView,
  KeyboardAvoidingView,
  Platform,
  Animated,
  Dimensions,
} from 'react-native';
import { showMessage } from 'react-native-flash-message';

import formatFieldWhenVisitorIsForeigner from '../helpers/formatFieldWhenVisitorIsForeigner';
import { useCentralizedValuesFallback } from '_/hooks/useCentralizedValuesFallback';
import { TextInput } from 'react-native-gesture-handler';
import CheckVisitorIsForeigner from '_/components/CheckVisitorIsForeigner';
const { height } = Dimensions.get('window');
const HEIGHT_ITEM = 146;

const CheckinInformationScreen: React.FC = () => {
  const { t } = useTranslation();
  const { customization } = useCustomization();
  const { ipad } = useAuth();
  const scrollViewRef = useRef<ScrollView>(null);
  const { goToNextScreen } = useNextCheckinScreen(AppRoute.CHECKIN_INFORMATION);

  const { selectedOrFallbackCustomization } = useCentralizedValuesFallback();

  const [customFields, setCustomFields] = useState(
    selectedOrFallbackCustomization?.fields as CustomFieldWithError[]
  );

  const [submitFailed, setSubmitFailed] = useState(false);
  const scrollY = useRef(new Animated.Value(0)).current;
  const [scrollSize, setScrollSize] = useState(0);
  const [focusedField, setFocusedField] = useState<CustomFieldWithError | undefined>();

  const inputRefs = useRef<React.RefObject<TextInput>[]>(
    customFields.map(() => React.createRef<TextInput>())
  );
  const [currentIndex, setCurrentIndex] = useState(1);

  const {
    loading,
    visit,
    setVisit,
    getVisitDataByField,
    setVisitDataByField,
    checkFields,
    resetData,
  } = useVisit();

  useEffect(() => {
    if (ipad?.singleQuestionEnabled) return;

    inputRefs.current[currentIndex]?.current?.focus?.();
  }, []);

  useEffect(() => {
    if (!ipad?.singleQuestionEnabled) return;

    const timeoutId = setTimeout(() => {
      inputRefs.current[currentIndex]?.current?.focus?.();
    }, 200);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [inputRefs, currentIndex]);

  useEffect(() => {
    const defaultFieldIds = ['primaryKey', 'name'];

    if (visit?.isRecurrent) {
      const alwaysRequiredInputFields = customFields.filter((field) => field.alwaysRequireInput);

      setCustomFields(
        customFields
          .filter((field) => defaultFieldIds.includes(field.id))
          .concat(alwaysRequiredInputFields)
      );

      const visitData = { ...visit };
      alwaysRequiredInputFields.forEach((field) => {
        const visitFieldType = field.type.toLowerCase() as keyof VisitsModel;

        visitData[visitFieldType] = null as any;
        visitData.customFields = visitData.customFields?.map((cf) => {
          if (cf.customFieldId === field.id) cf.value = '';
          return cf;
        });
      });

      setVisit(visitData);
    }
  }, [visit?.isRecurrent]);

  function handleSubmit() {
    const checkedFields = ipad?.singleQuestionEnabled
      ? checkFields([customFields[currentIndex]])
      : checkFields(customFields);

    if (checkedFields.some(({ hasError }) => hasError)) {
      if (!ipad?.singleQuestionEnabled) setCustomFields(checkedFields);
      showMessage({
        message: t('infoScreen.missingFieldsError'),
        backgroundColor: colors.danger,
      });
      scrollViewRef.current?.scrollTo({ animated: true, x: 0, y: 0 });
      setSubmitFailed(true);
      return;
    }

    if (ipad?.singleQuestionEnabled && currentIndex < customFields.length - 1) {
      if (submitFailed) setSubmitFailed(false);
      handleNextPress();
      return;
    }

    goToNextScreen();
  }

  function handleResetVisit() {
    resetData();
  }

  const keyboardType = useMemo(() => {
    switch (focusedField?.type) {
      case CustomFieldType.PHONE:
      case CustomFieldType.CPF:
        return 'numeric';
    }
    return 'default';
  }, [focusedField]);

  function onSubmitNextInput() {
    if (focusedField) {
      const lastField = ipad?.singleQuestionEnabled
        ? customFields[currentIndex].id
        : customFields[customFields.length - 1].id;

      if (focusedField.id === lastField) {
        handleSubmit();
        return;
      }
      const nextIndexInput = customFields.indexOf(focusedField) + 1;
      if (
        customFields[nextIndexInput]?.type === CustomFieldType.DROPDOWN ||
        focusedField === customFields[customFields.length - 1]
      ) {
        setFocusedField(undefined);
        return;
      }
      inputRefs.current[nextIndexInput]?.current?.focus?.();
    }
  }

  const handleNextPress = () => {
    setCurrentIndex(currentIndex + 1);
  };

  const handleBackPress = () => {
    setCurrentIndex(currentIndex - 1);
  };

  const handleFocus = (index: number) => {
    if (scrollViewRef.current && inputRefs.current[index]?.current) {
      // Obtém a posição do input e faz o scroll
      inputRefs.current[index].current.measureLayout(
        scrollViewRef.current.getInnerViewNode(),
        (x, y) => {
          scrollViewRef.current?.scrollTo({ y, animated: true });
        }
      );
    }
  };

  const customField = (field: CustomFieldWithError, index: number) => {
    field = formatFieldWhenVisitorIsForeigner(field, visit, t);

    // check if has some Document field before the current field
    if (field.type === CustomFieldType.DOCUMENT) {
      const firstDocumentField = customFields.find(
        (customField) =>
          customField.type === CustomFieldType.RG || customField.type === CustomFieldType.CPF
      );

      const hasDocumentBefore = firstDocumentField?.id !== field.id;

      if (hasDocumentBefore) {
        return null;
      }
    }

    const [checkField] = checkFields([field]);

    return (
      <CustomFieldInput
        key={field.id}
        value={getVisitDataByField(field) as string}
        isDisabled={field.disabled}
        onChangeText={(value) => setVisitDataByField(value, field)}
        customField={submitFailed ? checkField : field}
        countryCode={visit.countryCode}
        ref={inputRefs.current[index]}
        onFocus={() => {
          setFocusedField(field);
          handleFocus(index);
        }}
        onSubmitEditing={onSubmitNextInput}
        onBlur={() => setFocusedField(undefined)}
        onSubmit={handleSubmit}
      />
    );
  };

  const documentTypeField = customFields.find(
    (field) => field.type.toUpperCase() === CustomFieldType.CPF || field.type === CustomFieldType.RG
  );

  return (
    <KeyboardContainer
      keyboardOpen={!!focusedField}
      onSubmit={onSubmitNextInput}
      inputName={focusedField?.name || ''}
      onChange={(value) => setVisitDataByField(value, focusedField)}
      initialValue={getVisitDataByField(focusedField) as string}
      keyboardType={keyboardType}
    >
      <KeyboardAvoidingView
        enabled
        style={StyleSheet.absoluteFill}
        behavior={Platform.OS === 'ios' ? 'padding' : undefined}
      >
        <Animated.ScrollView
          ref={scrollViewRef}
          style={styles.container}
          keyboardShouldPersistTaps="always"
          onContentSizeChange={(w, h) => setScrollSize(h)}
          contentContainerStyle={{ paddingBottom: 60 }}
          onScroll={Animated.event([{ nativeEvent: { contentOffset: { y: scrollY } } }], {
            useNativeDriver: Platform.OS !== 'web',
          })}
          scrollEventThrottle={16}
        >
          <View
            style={[
              styles.container,
              { alignItems: 'center', paddingBottom: focusedField ? 60 : 0 },
            ]}
          >
            <ContainerWidthLimit>
              <Header onGoBack={handleResetVisit} />
              <ChevronDown
                scrollY={scrollY}
                isVisible={height < scrollSize - HEIGHT_ITEM}
                extraPadding={60}
                scrollSize={scrollSize}
                scrollViewRef={scrollViewRef}
              >
                <View style={styles.content}>
                  {ipad?.singleQuestionEnabled ? (
                    <>{customField(customFields[currentIndex], currentIndex)}</>
                  ) : (
                    <>{customFields?.map((field, index) => customField(field, index))}</>
                  )}

                  {documentTypeField && documentTypeField.id !== 'primaryKey' && (
                    <View>
                      <CheckVisitorIsForeigner
                        foreignerDocumentSelected={visit.isForeigner ?? false}
                        onClick={() => setVisit({ isForeigner: !visit.isForeigner })}
                        buttonKey={documentTypeField.type}
                      />
                    </View>
                  )}

                  <View
                    style={{
                      ...styles.buttonsContainer,
                      ...(currentIndex === 1
                        ? {
                            width: '60%',
                          }
                        : { width: '75%' }),
                    }}
                  >
                    {currentIndex !== 1 && (
                      <Button
                        backgroundColor={colors.white}
                        labelColor={customization?.mainColor || colors.primary}
                        loading={loading}
                        containerStyles={{
                          ...styles.buttonContainer,
                        }}
                        buttonStyles={[
                          styles.button,
                          {
                            justifyContent: loading ? 'center' : 'space-between',
                            borderWidth: 2,
                            borderColor: customization?.mainColor || colors.primary,
                          },
                        ]}
                        leftIcon="arrow-left"
                        onPress={handleBackPress}
                      >
                        {t('Back')}
                      </Button>
                    )}

                    <Button
                      backgroundColor={customization?.mainColor || colors.primary}
                      labelColor={customization?.backgroundColor || colors.white}
                      loading={loading}
                      containerStyles={{
                        flex: 1,
                      }}
                      buttonStyles={[
                        styles.button,
                        { justifyContent: loading ? 'center' : 'space-between' },
                      ]}
                      rightIcon="arrow-right"
                      onPress={handleSubmit}
                    >
                      {t('Next')}
                    </Button>
                  </View>
                </View>
              </ChevronDown>
            </ContainerWidthLimit>
          </View>
        </Animated.ScrollView>
      </KeyboardAvoidingView>
    </KeyboardContainer>
  );
};

export default CheckinInformationScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
  },
  content: {
    alignItems: 'center',
    flex: 1,
  },
  buttonContainer: {
    width: '24%',
  },
  buttonsContainer: {
    flexDirection: 'row',
    gap: 12,
  },
  button: {
    marginTop: 26,
    marginBottom: 22,
    height: 64,
  },
});
