import { useNavigation } from '@react-navigation/native';
import { IpadLang, Lang, VisitCheckType } from '@w3lcome/types';
import { HTMLRender, Text } from '_/components';
import ContainerWidthLimit from '_/components/ContainerWidthLimit';
import { fontSizes } from '_/config/theme';
import { textReplace } from '_/helpers/textReplace';
import { useAuth } from '_/hooks/AuthContext';
import { useCustomization } from '_/hooks/CustomizationContext';
import { useVisit } from '_/hooks/VisitContext';
import { AppRoute, CheckoutMessageProps } from '_/navigation/types';
import { visitsApi } from '_/services/api';
import i18n from '_/services/locale';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet } from 'react-native';

const CheckoutMessageScreen: React.FC = () => {
  const { t, i18n } = useTranslation();
  const { customization } = useCustomization();
  const navigation = useNavigation<CheckoutMessageProps>();
  const { ipad } = useAuth();

  const { visit } = useVisit();

  const checkout = async () => {
    if (visit.id) {
      await visitsApi.update(visit.id, {
        feedbacks: visit.feedbacks,
        checkoutAt: new Date(),
        checkoutByIpadId: ipad?.id,
        checkoutType: VisitCheckType.IPAD,
      });
    }
  };

  useEffect(() => {
    checkout();
    const timeoutId = setTimeout(() => {
      navigation.navigate(AppRoute.HOME);
    }, (customization?.ipadSeeYouDuration || 6) * 1000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [customization?.ipadSeeYouDuration]);

  const ipadSeeYouMessage = useMemo(() => {
    if (!customization?.ipadSeeYouMessage) return '';

    if (typeof customization.ipadSeeYouMessage === 'string') {
      return textReplace(
        customization.ipadSeeYouMessage.trim(),
        i18n.language as IpadLang,
        customization,
        visit
      );
    }

    const existingValues = Object.keys(customization.ipadSeeYouMessage);
    const langKey =
      existingValues.find((key) => key === i18n.language || key === Lang.EN) || existingValues[0];

    return textReplace(
      (customization.ipadSeeYouMessage[langKey as Lang] || '').trim(),
      i18n.language as IpadLang,
      customization,
      visit
    );
  }, [i18n.language, customization?.ipadDeliveriesMessage]);

  return (
    <View style={styles.container}>
      <ContainerWidthLimit>
        <View style={styles.content}>
          <View style={{ alignItems: 'center' }}>
            <Text size={fontSizes.xxxl}>{t('checkoutScreen.message')}</Text>
            <Text fontType="bold" size={fontSizes.xxxl}>
              {visit.name}
            </Text>
          </View>
          <View pointerEvents="none">
            {!!customization?.ipadSeeYouMessage && <HTMLRender html={ipadSeeYouMessage} />}
          </View>
        </View>
      </ContainerWidthLimit>
    </View>
  );
};

export default CheckoutMessageScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'space-evenly',
    paddingHorizontal: 64,
    textAlign: 'center',
  },
});
