import { useNavigation, useRoute } from '@react-navigation/native';
import { CustomFieldType, PrimaryKeyType } from '@w3lcome/types';
import { Text, Button, CustomFieldInput, Header, GDPRModal, QRCodeButton } from '_/components';
import CanceledModal from '_/components/CanceledModal';
import CheckVisitorIsForeigner from '_/components/CheckVisitorIsForeigner';
import ContainerWidthLimit from '_/components/ContainerWidthLimit';
import { GDPRButton } from '_/components/GDPRButton';
import KeyboardContainer from '_/components/KeyboardContainer';
import Modal from '_/components/Modal';
import ModalDoubleButton from '_/components/ModalDoubleButton';
import ModalMeeting from '_/components/ModalMeeting';
import ModalPhoneNumberValidator from '_/components/ModalPhoneNumberValidator';
import ModalQRCodeCamera from '_/components/ModalQRCodeCamera';
import appConfig from '_/config/app';
import { colors, fontSizes } from '_/config/theme';
import { useAuth } from '_/hooks/AuthContext';
import { useCustomization } from '_/hooks/CustomizationContext';
import { usePrinters } from '_/hooks/PrintersContext';
import { useVisit } from '_/hooks/VisitContext';
import { useNextCheckinScreen } from '_/hooks/useNextCheckinScreen';
import { CustomFieldWithError } from '_/interfaces/CustomFieldWithError';
import { VisitsModelCheckin } from '_/interfaces/VisitsModelCheckin';
import { AppRoute, CheckinProps } from '_/navigation/types';
import { smsOtpValidations } from '_/services/api';
import logger from '_/services/logger';
import useCountdownStore from '_/store/useCountdownStore';
import { isAfter, isBefore, subHours } from 'date-fns';
import Constants from 'expo-constants';
import { parsePhoneNumber } from 'libphonenumber-js';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet, TextInput, Keyboard, ScrollView } from 'react-native';
import { showMessage } from 'react-native-flash-message';

import formatFieldWhenVisitorIsForeigner from './helpers/formatFieldWhenVisitorIsForeigner';
import { useCentralizedValuesFallback } from '_/hooks/useCentralizedValuesFallback';
import { useChildrenCompanies } from '_/hooks/ChildrenCompaniesContex';
import { useDetailedVisitFlowLogging } from '_/hooks/useDetailedVisitFlowLogging';

const CheckinScreen: React.FC = () => {
  const { t } = useTranslation();
  const { ipad } = useAuth();
  const { goBack, navigate } = useNavigation<CheckinProps>();
  const inputRef = useRef<TextInput>(null);
  const [isGDPRModalVisible, setGDPRModalVisible] = useState(true);
  const { customization } = useCustomization();

  const route = useRoute();

  const meetingModalVisible = route.params;

  const { selectedOrFallbackCustomization, selectedOrFallbackGDPR, selectedOrFallbackCompany } =
    useCentralizedValuesFallback();

  const { selectedCompanyId, setSelectedCompanyId } = useChildrenCompanies();

  const {
    loading,
    setLoading,
    setVisit,
    getVisitorByPrimaryKey,
    getVisitDataByField,
    setVisitDataByField,
    isValidVisitDataByField,
    setVisitParser,
    setIsPrintVisitBadge,
    showMessageErrorUniqueVisit,
    resetData,
    visit,
    getVisitByQRCode,
    navigateToHome,
  } = useVisit();

  const [isMeetingModalVisible, setMeetingModalVisible] = useState(!!meetingModalVisible);
  const [isQRCodeReaderVisible, setQRCodeReaderVisible] = useState(false);
  const [isModalPrinterVisible, setModalPrinterVisible] = useState(false);
  const [modalCheckVisitorNameVisible, setModalCheckVisitorNameVisible] = useState(false);
  const [errorModalMessage, setErrorModalMessage] = useState<string>();
  const { goToNextScreen } = useNextCheckinScreen(AppRoute.CHECKIN);
  const { printVisitBadge } = usePrinters();
  const [counter, startCountdown] = useCountdownStore((state) => [
    state.counter,
    state.startCountdown,
  ]);
  const [isFocusedInput, setIsFocusedInput] = useState(false);
  const [verifyPhoneNumberModal, setVerifyPhoneNumberModal] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');

  const { getCheckInStartTime } = useDetailedVisitFlowLogging();

  const primaryKeyField = useMemo(() => {
    const primaryKey = selectedOrFallbackCustomization?.fields?.[0];

    return formatFieldWhenVisitorIsForeigner(primaryKey as CustomFieldWithError, visit, t);
  }, [visit.isForeigner]);

  const isNextEnabled = isValidVisitDataByField(primaryKeyField);

  const returnRoute = () => {
    if (selectedCompanyId) {
      setSelectedCompanyId(undefined);
    }
    resetData();
  };

  const checkCardNumberToPrintBadge = useMemo(() => {
    if (ipad?.printerId && selectedOrFallbackCompany?.id !== appConfig.companyIdSbt) {
      if (Constants.expoConfig?.extra?.checkCardNumberToPrintBadge === 'true') {
        if (visit.cardNumber) {
          return true;
        }
        return false;
      }
      return true;
    }

    return false;
  }, [visit, ipad, selectedOrFallbackCompany]);

  const primaryKey: PrimaryKeyType = useMemo(() => {
    if (!selectedOrFallbackCustomization?.primaryKey) {
      return PrimaryKeyType.EMAIL;
    }
    return selectedOrFallbackCustomization.primaryKey;
  }, [selectedOrFallbackCustomization]);

  const handleSubmit = async () => {
    const visitor = await getVisitorByPrimaryKey();

    if (!visitor) {
      return;
    }

    getCheckInStartTime(visitor);

    if (
      !selectedOrFallbackCustomization?.ipadHideVisitorInfoEnable &&
      primaryKey === PrimaryKeyType.PHONE &&
      selectedOrFallbackCustomization?.otpValidationEnable &&
      !visitor.meeting &&
      !visitor.smsOtpValidationId &&
      !visitor?.isCanceled
    ) {
      await requestOTPValidation(visit);
      setVerifyPhoneNumberModal(true);
      return;
    }

    if (
      selectedOrFallbackCustomization?.ipadHideVisitorInfoEnable &&
      visitor.name &&
      !visitor?.isCanceled &&
      !visitor.meeting
    ) {
      setVisit({ isRecurrent: true });
      setModalCheckVisitorNameVisible(true);
      return;
    }

    if (visitor.meeting) {
      Keyboard.dismiss();
      const verifyMeetingValidity = checkVisitValidity(visitor);
      if (verifyMeetingValidity) {
        setMeetingModalVisible(true);
      } else {
        navigate(AppRoute.CHECKIN_INFORMATION);
      }
    } else {
      if (!visitor?.isCanceled) {
        navigate(AppRoute.CHECKIN_INFORMATION);
      }
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (!selectedOrFallbackCustomization?.gdprEnabled) {
        inputRef.current?.focus();
      }
    }, 800);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  const anonymousCheckIn = () => {
    setVisit({ isAnonymous: true });
    setGDPRModalVisible(false);
    goToNextScreen();
  };

  const requestOTPValidation = useCallback(
    async (visit: Partial<VisitsModelCheckin>) => {
      if (!visit.phone) {
        return;
      }

      if (visit.phone === phoneNumber && counter !== 0) {
        return;
      }

      try {
        await (selectedCompanyId
          ? smsOtpValidations.childrenRequestOtp(selectedCompanyId, {
              phone: parsePhoneNumber(visit.phone, visit.countryCode).number,
            })
          : smsOtpValidations.insert({
              phone: parsePhoneNumber(visit.phone, visit.countryCode).number,
            }));

        setPhoneNumber(visit.phone);
        startCountdown(selectedOrFallbackCustomization?.otpValidationTimeToExpireInMinutes || 2);
      } catch (error) {
        logger(error);
      }
    },
    [counter, phoneNumber]
  );

  function checkVisitValidity(visit: Partial<VisitsModelCheckin>) {
    if (visit.checkinAt || !visit.meeting) {
      return false;
    }

    const now = new Date();
    const endCheckinAt = new Date(visit.meeting?.end);
    const startCheckinAt = subHours(
      new Date(visit.meeting.start),
      selectedOrFallbackCustomization?.timeToCheckinBeforeMeetingInHours || 1
    );

    if (isBefore(now, startCheckinAt)) {
      return false;
    }

    if (isAfter(now, endCheckinAt)) {
      return false;
    }

    return true;
  }

  async function handleScannedQRCode(data: string) {
    setLoading(true);
    setQRCodeReaderVisible(false);
    try {
      const visit = await getVisitByQRCode(data);

      if (visit) {
        const isValidVisit = checkVisitValidity(visit as Partial<VisitsModelCheckin>);
        if (isValidVisit) {
          setVisitParser(visit);
          setMeetingModalVisible(true);
        } else {
          setErrorModalMessage(t('QRCodeAlreadyUsed'));
        }
      } else {
        setErrorModalMessage(t('InvalidQRCode'));
      }
    } catch (error) {
      setErrorModalMessage(t('InvalidQRCode'));
    }
    setLoading(false);
  }

  const handleNextScreen = () => {
    if (!isNextEnabled) {
      showMessage({
        message: t('infoScreen.missingFieldsError'),
        backgroundColor: colors.danger,
      });
      return;
    }

    if (selectedOrFallbackCustomization?.gdprEnabled && selectedOrFallbackGDPR?.gdprText) {
      if (visit.gdprAgreedAt) {
        handleSubmit();
      } else {
        setGDPRModalVisible(true);
      }
    } else {
      handleSubmit();
    }
  };

  function handleAcceptGDPR() {
    setVisit({ gdprAgreedAt: new Date() });
    setGDPRModalVisible(false);
    setImmediate(() => {
      inputRef.current?.focus();
    });
  }

  function handleOpenGDPR() {
    Keyboard.dismiss();
    setGDPRModalVisible(true);
  }

  function hideModalMeeting(isMeetingConfirmed = true) {
    setMeetingModalVisible(false);

    if (!isMeetingConfirmed) {
      resetData();
      goBack();
    }
  }

  function handleConfirmVisitorName() {
    setModalCheckVisitorNameVisible(false);

    if (
      visit.isRecurrent &&
      selectedOrFallbackCustomization?.fields?.some((field) => field.alwaysRequireInput)
    ) {
      navigate(AppRoute.CHECKIN_INFORMATION);
      return;
    }

    goToNextScreen();
  }

  const keyboardType = useMemo(() => {
    switch (primaryKeyField?.type) {
      case CustomFieldType.PHONE:
      case CustomFieldType.CPF:
        return 'numeric';
    }
    return 'default';
  }, [primaryKeyField?.type]);

  const documentTypeField = selectedOrFallbackCustomization?.fields?.find(
    (field) => field.type.toUpperCase() === CustomFieldType.CPF || field.type === CustomFieldType.RG
  );

  return (
    <>
      <KeyboardContainer
        keyboardOpen={isFocusedInput}
        onSubmit={handleNextScreen}
        inputName={primaryKey.toLocaleLowerCase()}
        onChange={(value) => setVisitDataByField(value, primaryKeyField)}
        keyboardType={keyboardType}
      >
        <ScrollView
          scrollEnabled={isGDPRModalVisible}
          style={[styles.container, StyleSheet.absoluteFill]}
          keyboardShouldPersistTaps="always"
        >
          <View style={[styles.container, { alignItems: 'center' }]}>
            <ContainerWidthLimit>
              <Header onGoBack={returnRoute} />

              <View style={styles.content}>
                <Text center size={fontSizes.xl}>
                  {t(`homeScreen.message.${primaryKey}`)}
                </Text>
                <CustomFieldInput
                  editable={!loading}
                  value={getVisitDataByField(primaryKeyField) as string}
                  onChangeText={(value) => setVisitDataByField(value, primaryKeyField)}
                  customField={primaryKeyField}
                  ref={inputRef}
                  countryCode={visit.countryCode}
                  onSubmitEditing={handleSubmit}
                  onFocus={() => setIsFocusedInput(true)}
                  onBlur={() => setIsFocusedInput(false)}
                  onSubmit={handleNextScreen}
                />

                <Button
                  backgroundColor={customization?.mainColor || colors.primary}
                  labelColor={customization?.backgroundColor || colors.white}
                  loading={loading}
                  containerStyles={styles.buttonContainer}
                  buttonStyles={[
                    styles.button,
                    { justifyContent: loading ? 'center' : 'space-between' },
                  ]}
                  rightIcon="arrow-right"
                  onPress={handleNextScreen}
                >
                  {t('Next')}
                </Button>
              </View>
            </ContainerWidthLimit>
            {selectedOrFallbackCustomization?.qrCodeCheckinEnabled &&
              !selectedOrFallbackCustomization.qrCodeCheckinAtHomeEnabled && (
                <QRCodeButton
                  onPress={() => {
                    setQRCodeReaderVisible(true);
                    Keyboard.dismiss();
                  }}
                  loading={loading}
                />
              )}
            {selectedOrFallbackCustomization?.gdprEnabled && !!selectedOrFallbackGDPR?.gdprText && (
              <GDPRButton onOpen={handleOpenGDPR} />
            )}
            {documentTypeField && documentTypeField.id === 'primaryKey' && (
              <View>
                <CheckVisitorIsForeigner
                  foreignerDocumentSelected={visit.isForeigner ?? false}
                  onClick={() => setVisit({ isForeigner: !visit.isForeigner })}
                  buttonKey={documentTypeField.type}
                />
              </View>
            )}
          </View>
        </ScrollView>
      </KeyboardContainer>

      {selectedOrFallbackCustomization?.gdprEnabled && !!selectedOrFallbackGDPR?.gdprText && (
        <GDPRModal
          isVisible={isGDPRModalVisible}
          onReject={
            selectedOrFallbackCustomization?.gdprAnonymouslyCheckin ? anonymousCheckIn : goBack
          }
          onAccept={handleAcceptGDPR}
        />
      )}

      {isMeetingModalVisible && !visit?.isCanceled && (
        <ModalMeeting
          isModalVisible={isMeetingModalVisible}
          showModalPrinter={() =>
            selectedOrFallbackCompany?.id !== appConfig.companyIdSbt
              ? setModalPrinterVisible(true)
              : goToNextScreen()
          }
          hideModalMeeting={hideModalMeeting}
        />
      )}

      {modalCheckVisitorNameVisible && (
        <ModalDoubleButton
          isModalVisible={modalCheckVisitorNameVisible}
          onCloseText={t('No')}
          onFinishText={t('Yes')}
          onClose={() => {
            setModalCheckVisitorNameVisible(false);
            resetData();
          }}
          onFinish={() => handleConfirmVisitorName()}
        >
          <Text size={fontSizes.xl}>{t('areYou', { name: visit.name })}</Text>
        </ModalDoubleButton>
      )}

      {visit?.isCanceled && <CanceledModal />}

      <ModalQRCodeCamera
        cameraType={ipad?.settingsQRCodeCameraPosition.toLowerCase() as any}
        isScannerEnabled={
          !loading && !errorModalMessage && !isMeetingModalVisible && isQRCodeReaderVisible
        }
        isVisible={isQRCodeReaderVisible}
        onClose={() => setQRCodeReaderVisible(false)}
        onScan={handleScannedQRCode}
      />

      {showMessageErrorUniqueVisit && (
        <>
          {checkCardNumberToPrintBadge ? (
            <ModalDoubleButton
              message={t('checkinScreen.uniqueVisit')}
              messageBold={t('checkinScreen.printQuestion')}
              isModalVisible={showMessageErrorUniqueVisit}
              onCloseText={t('checkinScreen.Back')}
              onFinishText={t('checkinScreen.reprintTheLabel')}
              onClose={() => {
                navigateToHome(navigate);
              }}
              onFinish={async () => {
                await printVisitBadge({ ...visit, checkinAt: new Date() });
                showMessage({
                  duration: 6000,
                  message: `${t('checkinScreen.printing')}...`,
                  description: t('checkinScreen.waitPrint'),
                  backgroundColor: colors.warning,
                });
                navigateToHome(navigate);
              }}
            />
          ) : (
            <Modal
              icon="alert-circle"
              iconColor={colors.danger}
              message={t('checkinScreen.uniqueVisit')}
              isVisible={showMessageErrorUniqueVisit}
              onClose={() => navigateToHome(navigate)}
            />
          )}
        </>
      )}

      {isModalPrinterVisible && (
        <ModalDoubleButton
          title={`${visit?.name || t('Hello')}, ${t('checkinScreen.print')}`}
          onCloseText={t('No')}
          onFinishText={t('Yes')}
          onClose={() => {
            setIsPrintVisitBadge(false);
            setModalPrinterVisible(false);
            goToNextScreen();
          }}
          onFinish={() => {
            setIsPrintVisitBadge(true);
            setModalPrinterVisible(false);
            goToNextScreen();
          }}
          isModalVisible={isModalPrinterVisible}
        />
      )}

      {verifyPhoneNumberModal && (
        <ModalPhoneNumberValidator
          isModalVisible={verifyPhoneNumberModal}
          closeModal={setVerifyPhoneNumberModal}
          successModal={() => navigate(AppRoute.CHECKIN_INFORMATION)}
        />
      )}

      <Modal
        icon="alert-circle"
        iconColor={colors.danger}
        message={errorModalMessage}
        isVisible={!!errorModalMessage}
        onClose={() => setErrorModalMessage(undefined)}
      />
    </>
  );
};

export default CheckinScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
  },
  content: {
    alignItems: 'center',
  },
  buttonContainer: {
    width: '60%',
  },
  button: {
    marginTop: 26,
    marginBottom: 22,
    height: 64,
  },
});
