import * as faceapi from 'face-api.js';
import logger from '_/services/logger';
import * as FileSystem from 'expo-file-system';

class FaceApi {
  private modelURL = 'https://cdn.jsdelivr.net/npm/@vladmandic/face-api@latest/model/';
  loaded = false;

  constructor() {
    this.loadModel();
  }

  async loadModel() {
    if (this.loaded) {
      return;
    }

    try {
      await faceapi.nets.tinyFaceDetector.loadFromUri(this.modelURL);
      await faceapi.nets.faceLandmark68Net.loadFromUri(this.modelURL);
      this.loaded = true;
    } catch (e) {
      this.loaded = false;
    }
  }

  async getFaceScore(file: HTMLImageElement | HTMLVideoElement) {
    let score = 0;
    let eyesOpen = false;
    try {
      await this.loadModel();
      if (file) {
        const detection = await faceapi
          .detectAllFaces(file, new faceapi.TinyFaceDetectorOptions())
          .withFaceLandmarks();

        if (detection) {
          score = detection[0]?.detection.score || 0;

          const landmarks = detection[0]?.landmarks;
          const leftEye = landmarks?.getLeftEye();
          const rightEye = landmarks?.getRightEye();

          // Calcula abertura dos olhos
          const EYE_MIN_OPEN = 0.29;
          const leftEyeOpen = this.calculateEyeOpen(leftEye);
          const rightEyeOpen = this.calculateEyeOpen(rightEye);

          eyesOpen = leftEyeOpen > EYE_MIN_OPEN && rightEyeOpen > EYE_MIN_OPEN;
        }
      }
    } catch (error) {
      logger(error);
    }

    return { score, eyesOpen };
  }

  async getImageBase64FromUri(uri: string) {
    const image = new Image();
    try {
      let img64 = uri;

      if (!uri.includes('base64')) {
        uri = await FileSystem.readAsStringAsync(uri, {
          encoding: FileSystem.EncodingType.Base64,
        });
      }

      image.src = img64;
    } catch (error) {
      logger(error);
    }

    return image;
  }

  async validateFaceScore(
    uri: string,
    minScore?: number | null,
    frameElement?: HTMLImageElement | HTMLVideoElement | null
  ) {
    const faceInput = frameElement || (await this.getImageBase64FromUri(uri));
    const { score, eyesOpen } = await this.getFaceScore(faceInput);

    return score >= (minScore || Number(process.env.PICTURE_MIN_SCORE) || 0.9) && eyesOpen;
  }

  private calculateEyeOpen(eye: faceapi.Point[]) {
    if (!eye) return 0;

    const A = this.eyeDistance(eye[1], eye[5]);
    const B = this.eyeDistance(eye[2], eye[4]);
    const C = this.eyeDistance(eye[0], eye[3]);

    return (A + B) / (2.0 * C);
  }

  private eyeDistance(point1: faceapi.Point, point2: faceapi.Point) {
    return Math.sqrt(Math.pow(point1.x - point2.x, 2) + Math.pow(point1.y - point2.y, 2));
  }
}

const faceApi = new FaceApi();

export default faceApi;
